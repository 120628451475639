import { CheckOutlined, UserOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { Alert, Button, Card, Typography } from "antd";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./Pricing.css";
const { Title } = Typography;

const TFLPricing = () => {
  const { user } = useAuth0();

  const monthlyStripeLink = "https://buy.stripe.com/00g5m73Up55HdOM149";
  const navigate = useNavigate();
  return (
    <div className="pricing-page">
      {user?.latest_tfl_sub_status && (
        <Alert
          message={`Your subscription will expire in ${moment(
            user?.latest_tfl_sub_end_date
          ).diff(moment(), "days")} days`}
          type="success"
        />
      )}
      <div className="pricing-header" style={{ marginTop: "3rem" }}>
        <Title level={2}>Choose Your Plan That Fits Your Budget</Title>
      </div>
      <div className="pricing">
        <Card
          title={
            <div className="pricing-title">
              <Title level={4}>Free </Title>

              <div>
                <UserOutlined style={{ fontSize: "20px" }} />
              </div>
            </div>
          }
          style={{ width: 300 }}
        >
          <div className="item-flex">
            <Title level={2}>USD 0</Title>
            <span>/month</span>
          </div>
          <Button
            type="primary"
            size={"large"}
            style={{ margin: "30px 0" }}
            onClick={() => navigate("/")}
            disabled={user?.latest_tfl_sub_status}
          >
            Get started
          </Button>
          <Title level={5}>Free features include:</Title>
          <p>
            <CheckOutlined style={{ marginRight: "0.6rem" }} /> 3 free SQL
            conversions
          </p>
          <p>
            <CheckOutlined style={{ marginRight: "0.6rem" }} />
            Can see all pages
          </p>
        </Card>

        <Card
          title={
            <div className="pricing-title">
              <Title level={4}>Pro </Title>

              <div>
                <UserOutlined style={{ fontSize: "20px" }} />
              </div>
            </div>
          }
          style={{ width: 300 }}
        >
          <div className="item-flex">
            <Title level={2}>USD 70</Title>
            <span>/month</span>
          </div>
          <Button
            type="primary"
            size={"large"}
            style={{ margin: "30px 0" }}
            onClick={() => window.open(monthlyStripeLink, "_self")}
          >
            Contact us
          </Button>
          <Title level={5}>All features of the free plan plus:</Title>
          <p>
            <CheckOutlined style={{ marginRight: "0.6rem" }} />
            All features unlocked
          </p>
          <p>
            <CheckOutlined style={{ marginRight: "0.6rem" }} /> Unlimited SQL
            conversions
          </p>
          <p>
            <CheckOutlined style={{ marginRight: "0.6rem" }} /> Unlimited export
            documents{" "}
          </p>
        </Card>
        <Card
          title={
            <div className="pricing-title">
              <Title level={4}>Enterprise </Title>

              <div>
                <UserOutlined style={{ fontSize: "20px" }} />
              </div>
            </div>
          }
          style={{ width: 300 }}
        >
          <div className="item-flex">
            ` <div level={5}>Custom pricing tailored for your business.</div>
          </div>
          <Button
            type="primary"
            size={"large"}
            style={{ margin: "45px 0px 30px 0" }}
            onClick={() => window.open("https://not.rocketsci.ai/contact")}
          >
            Contact us
          </Button>
          <Title level={5}>All features of the Pro plan plus:</Title>
          <p>
            <CheckOutlined style={{ marginRight: "0.6rem" }} /> Migration tool
            access
          </p>
          <p>
            <CheckOutlined style={{ marginRight: "0.6rem" }} /> Priority
            customer support
          </p>
          <p>
            <CheckOutlined style={{ marginRight: "0.6rem" }} /> Flexible payment
            options
          </p>
          <p>
            <CheckOutlined style={{ marginRight: "0.6rem" }} /> Custom contract
          </p>
        </Card>
        <Card
          title={
            <div className="pricing-title">
              <Title level={4}>Migration tool </Title>

              <div>
                <UserOutlined style={{ fontSize: "20px" }} />
              </div>
            </div>
          }
          style={{ width: 300 }}
        >
          <div className="item-flex">
            <div level={5}>
              Contact us to request a quote for migration tool
            </div>
          </div>
          <Button
            type="primary"
            size={"large"}
            style={{ margin: "45px 0px 30px 0" }}
            onClick={() => window.open("https://not.rocketsci.ai/contact")}
          >
            Contact us
          </Button>
          <Title level={5}>Migration Tool Access</Title>
          <p>
            <CheckOutlined style={{ marginRight: "0.6rem" }} />
            Alteryx to Prep conversion
          </p>
        </Card>
      </div>
    </div>
  );
};

export default TFLPricing;
