import { useAuth0 } from "@auth0/auth0-react";
import { Button, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./AppHeader.css";

import { useNavigate } from "react-router-dom";
const AppHeader = () => {
  // Inside your AppHeader component
  const { isAuthenticated, logout } = useAuth0();
  const navigate = useNavigate();

  const location = useLocation();
  const pathName = location.pathname;

  useEffect(() => {
    if (pathName) {
      setCurrent(pathName.split("/")[1] || "home");
    }
  }, [pathName]);

  const items = [
    {
      label: <div onClick={() => navigate("/")}>Home</div>,
      key: "home",
    },
    {
      label: <div onClick={() => navigate("/pricing")}>Pricing</div>,
      key: "pricing",
    },
    {
      label: <div onClick={() => navigate("/contact")}>Contact Us</div>,
      key: "contact",
    },
  ];
  const [current, setCurrent] = useState("home");
  const onClick = (e) => {
    console.log(e.key);
    setCurrent(e.key);
  };
  return (
    <div className="header-container">
      <div className="left-aligned-section">
        <div className="logo-placeholder">
          <Link to="/" className="header-logo">
            <img src="rkt_sci_mini.svg" alt="Logo" className="logo-image" />
          </Link>
        </div>
        <Menu
          onClick={onClick}
          selectedKeys={[current]}
          mode="horizontal"
          items={items}
        />
      </div>
      <div className="right-aligned-section">
        {isAuthenticated && (
          <Button
            type="primary"
            onClick={() => logout({ returnTo: window.location.origin })}
          >
            Log Out
          </Button>
        )}
        <div className="settings-placeholder"></div>
      </div>
    </div>
  );
};

export default AppHeader;
