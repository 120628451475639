import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import { ConfigProvider } from 'antd';

const Root = () => {
  return (
    <Auth0Provider
      domain="dev-2yfheumgi03swqsw.us.auth0.com"
      clientId="P3VTWfbzvjkQWOOmgNcYWKFV43LCw09l"
      redirectUri={window.location.origin}
    >
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimary: '#7055F1',
              borderRadius: '6px'
            },
            Menu: {
              horizontalItemSelectedColor: '#7055F1',
              horizontalItemHoverColor: '#7055F1',
              itemSelectedBg: "#7055F11D",
              itemSelectedColor: '#7055F1',

            },
            Alert: {
              lineWeight: '1.5px',
              colorInfoBg: '#7055F12B',
              colorInfoBorder: '#7055F1'
            },
            Checkbox: {
              colorPrimary: '#7055F1',
              colorPrimaryHover: '#7055F19D'
            },
            Select: {
              colorPrimary: '#7055F1',
              colorPrimaryHover: '#7055F1',
              colorTextPlaceholder: '#444'
              }
          }
        }}
      >
        <App />
      </ConfigProvider>
    </Auth0Provider>
  );
};

export default Root;
