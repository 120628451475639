import React, { useState } from "react";
import { Menu } from "antd";
import TFLPricing from "./TFLPricing";
import TWBPricing from "./TWBPricing";
const Pricing = () => {
  const items = [
    {
      label: "TFL",
      key: "tfl",
    },
    {
      label: "TWB",
      key: "twb",
    },
  ];
  const [current, setCurrent] = useState("tfl");

  const onClick = (e) => {
    setCurrent(e.key);
  };
  return (
    <div className="pricing-page">
      <div className="pricing-header">
        <Menu
          onClick={onClick}
          selectedKeys={[current]}
          mode="horizontal"
          items={items}
        />
      </div>

      {current === "tfl" ? <TFLPricing /> : <TWBPricing />}
    </div>
  );
};

export default Pricing;
