import "./App.css";
import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import ProfilePage from "./components/ProfilePage";
import AppHeader from "./components/AppHeader";
import Pricing from "./pages/Pricing";
import Contact from "./pages/Contact";

function App() {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();
  const location = useLocation();
  const pathName = location.pathname;
  useEffect(() => {
    if (!isAuthenticated && !isLoading && pathName !== "/contact") {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, loginWithRedirect, pathName]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      <AppHeader />
      <Routes>
        <Route path="/" element={<ProfilePage />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </div>
  );
}

function AppWithRouter() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWithRouter;
