import { Button, Card, Form, Input, message } from "antd";
import React from "react";
import "./Contact.css";

const Contact = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const scriptURL =
    "https://script.google.com/macros/s/AKfycbypld3Bpn9iJ50rXYmH-hYC9_C_RRK-kIIz4DhJ9g0ZW4RnB8jRj4G0H7n0VSzkiC8w/exec";
  const handleSubmit = (values) => {
    console.log(values);
    const formData = new FormData();
    Object.keys(values).forEach((key) => formData.append(key, values[key]));
    fetch(scriptURL, { method: "POST", body: formData, mode: "no-cors" })
      .then((response) => {
        if (response) {
          messageApi.open({
            type: "success",
            content:
              "Thank you for contacting us, we will contact you as soon as possible ",
          });
        }
      })
      .catch((error) => console.error("Error!", error.message));
  };
  return (
    <div className="contact-container">
      {contextHolder}
      <div className="contact-left">
        <h1>Contact us</h1>
        <p>
          Need to get in touch with us? Please fill out the form to get more
          information
        </p>
      </div>
      <div className="contact-right">
        <Card
          style={{
            width: "100%",
          }}
        >
          <Form layout="vertical" onFinish={handleSubmit}>
            <Form.Item
              label="First name"
              tooltip="This is a required field"
              name="First Name"
              rules={[
                {
                  required: true,
                  message: "Please input!",
                },
              ]}
            >
              <Input placeholder="First name" />
            </Form.Item>
            <Form.Item
              label="Last name"
              rules={[
                {
                  required: true,
                  message: "Please input!",
                },
              ]}
              tooltip="This is a required field"
              name="Last name"
            >
              <Input placeholder="Last name" />
            </Form.Item>

            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please input!",
                },
              ]}
              label="Email"
              tooltip="This is a required field"
              name="Email"
            >
              <Input placeholder="Email" />
            </Form.Item>

            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please input!",
                },
              ]}
              label="What we can help you with?"
              tooltip="This is a required field"
              name="Message"
            >
              <Input.TextArea rows={4} />
            </Form.Item>

            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please input!",
                },
              ]}
            >
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default Contact;
