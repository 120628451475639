import { UserOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Avatar,
  Button,
  Card,
  Form,
  Input,
  Modal,
  Spin,
  Tag,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import "./ProfilePage.css";

const { Title } = Typography;
const { Item } = Form;

const ProfilePage = () => {
  const { user, isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

  const [isModalVisible, setIsModalVisible] = useState(false);

  console.log("User Info:", user);

  useEffect(() => {
    if (!isAuthenticated) {
      // If the user is not authenticated, redirect them to the login page
      loginWithRedirect();
    } else if (user) {
      console.log("Stripe Customer ID:", user["stripe_customer_id"]);
      console.log(
        "Latest Subscription End Date:",
        user["latest_subscription_end_date"]
      );
      console.log(
        "Latest Subscription Status:",
        user["latest_subscription_status"]
      );

      if (user["latest_subscription_status"] !== "active") {
        setIsModalVisible(true);
      }
    }
  }, [isAuthenticated, loginWithRedirect, user, isLoading]);

  const handleOk = () => {
    setIsModalVisible(false);
    // Redirect to the pricing page
    window.location.href = "/pricing";
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  if (isLoading) {
    return <Spin />;
  }
  const handleTWBExplorer = () => {
    window.location.href = "https://twb.rocketsci.ai";
  };

  const handleTFLExplorer = () => {
    window.location.href = "https://prep.rocketsci.ai";
  };

  return (
    <div className="cards-container">
      <div className="profile-container">
        <Card className="profile-card">
          <div className="profile-avatar">
            <Avatar size={128} icon={<UserOutlined />} src={user?.picture} />
          </div>
          <div className="profile-info">
            <Title level={4} className="profile-title">
              {user?.name || "Your Name"}
            </Title>
            <br />
          
          
              <>
                <p>
                  Subscription Status:{"  "}
                  <Tag
                    color={
                      user?.["latest_sub_status"] ? "purple" : "orange"
                    }
                  >
                    {user?.["latest_sub_status"] || " No active plan"}
                  </Tag>
                </p>
                <p>
                 Subscription End Date:{" "}
                  {user?.["latest_sub_end_date"]
                    ? new Date(
                        user["latest_sub_end_date"]
                      ).toLocaleDateString()
                    : "_"}
                </p>
              </>
          
          </div>
          <br />
          <Form layout="vertical">
            <Item label="Full Name">
              <Input placeholder="Full Name" defaultValue={user?.name} />
            </Item>
            <Item label="Email">
              <Input placeholder="Email" defaultValue={user?.email} disabled />
            </Item>
          </Form>
        </Card>
        <Card className="explorer-card">
          <Title level={4} className="explorer-title">
            Select an app
          </Title>
          <br />
          <div className="explorer-buttons">
            <Button type="primary" onClick={handleTWBExplorer}>
              TWB Explorer
            </Button>
            <Button type="primary" onClick={handleTFLExplorer}>
              TFL Explorer
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ProfilePage;
